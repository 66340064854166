import React from "react";
import { PatientCarePlanWidget } from "../PatientCarePlanWidget";
import { GoalAndObjectiveWidget } from "./GoalAndObjectiveWidget";
import {
    getCareGoalAndObjectiveNoteVersion,
    getCareGoalAndObjectivesFromNote,
} from "../WorkflowFunctions";

/**
 * Displays below the video component in the guided session
 */
export const CarePlanWidget = ({ noteContent, patientDetails, carePlanTips }) => {
    const version = getCareGoalAndObjectiveNoteVersion(noteContent);
    const goalsObjectivesProgress = getCareGoalAndObjectivesFromNote(noteContent, version);
    return (
        <>
            {version === "1" && (
                <PatientCarePlanWidget
                    patientDetails={patientDetails}
                    carePlanTips={carePlanTips}
                />
            )}
            {version === "2" && <GoalAndObjectiveWidget goalsObjectivesProgress={goalsObjectivesProgress}/>}
        </>
    );
};
