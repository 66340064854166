import React, { Component } from "react";
import { api } from "../../../../APIRequests";
import { connect } from "react-redux";
import { noteV2Display } from "../../../../components/Provider/PatientView/MemberProfile/MemberProfileNotes";
import _ from "lodash";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { createEmptyNote } from "../../../../components/Common/GuidedVideoCall/Steps/ProviderWorkflow/WorkflowFunctions";
import { getCPTWorkflow } from "../../../../components/Common/GuidedVideoCall/Steps/ProviderWorkflow/ProviderWorkflows";
import { providerTypeToDefaultNoteEventType } from "../../../../components/Provider/PatientView/MemberProfile/MemberProfileHeader";
import {
    allottedTimeFactory,
    PRESCRIBE,
} from "../../../../components/Provider/ScheduleAvailability/constants";
import { getCompletedCalls } from "./NewNote";
import { GUIDED_SESSION_CPT_OPTIONS } from "../../../../components/Provider/PatientView/MemberProfile/MemberProfileNotesDuration";
import { NotesLinkCall } from "../../../../components/Provider/PatientView/MemberProfile/MemberProfileNotesLinkCall";
import EditNoteWrapper from "./EditNoteWrapper";
import { fetchNecessaryNotesByWorkflow } from "../../../../components/Common/GuidedVideoCall/Steps/ProviderVideoWorkflowStep";

export const formatAllottedTime = (allottedTime) => {
    let hours = Math.floor(allottedTime / 60);
    let callTimer;
    if (hours) {
        callTimer = `0${hours}:`;
    } else {
        callTimer = "";
    }
    let minutes = Math.floor(allottedTime % 60);
    callTimer += minutes < 10 ? `0${minutes}:00` : `${minutes}:00`;
    return callTimer;
};

export function updateNote(currentNote, nested, key, value) {
    let clonedNote = _.cloneDeep(currentNote);

    if (nested) {
        clonedNote.content[key] = _.cloneDeep(value);
    } else {
        clonedNote[key] = _.cloneDeep(value);
    }
    return clonedNote;
}

class NewNoteV2__EXPERIMENTAL_GOALS_AND_OBJECTIVES_GUIDED_SESSION_DISABLED extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientId: props.match.params.patientId,
            noteErrors: {},
            event_type: null,
            patient: null,
            note: null,
            lastNote: null,
            patientDiagnosis: null,
            workflow: null,
        };
    }

    getCPTOptions = () => {
        return this.props.profile.provider_type
            ? GUIDED_SESSION_CPT_OPTIONS.filter((option) =>
                  option.provider_types.includes(this.props.profile.provider_type),
              )
            : [];
    };

    componentDidMount = async () => {
        try {
            let urlParams = { patientId: this.state.patientId };
            let patient = (await api.shared.fetch_patient_details({ urlParams })).user;

            const patientDiagnosis = await api.shared.get_patient_diagnosis({ urlParams });

            const event_type = providerTypeToDefaultNoteEventType(this.props.profile);
            const data = {
                event_type: event_type,
            };
            const questionSet = await api.shared.get_patient_call_question_sets({ data });
            const workflow = _.cloneDeep(getCPTWorkflow(event_type, this.props.profile));

            const validNoteTypes = [
                "Video Consultation",
                "Check-in",
                "Care Coordination",
                "Initial Clinical Assessment",
                "Care Communication",
                "Case Management",
                null,
            ];

            const lastCompletedNoteByProviderIdQueryParams = {
                provider_id: this.props.profile.username,
                version: workflow.details.noteVersion,
                note_state_is_not: "draft",
                limit_one_per_provider_type: true,
                completed_date_time_exists: true,
                is_not_patient_no_show: true,
                note_types: validNoteTypes.join(","),
            };
            let lastNoteCompletedByCurrentProviderResponse =
                await api.shared.fetch_patient_progress_notes({
                    urlParams,
                    queryParams: lastCompletedNoteByProviderIdQueryParams,
                });
            const lastNote = lastNoteCompletedByCurrentProviderResponse
                ? lastNoteCompletedByCurrentProviderResponse[0]
                : null;

            const lastCompletedNotesByOtherProvidersQueryParams = {
                provider_type_begins_with: workflow.details.viewLastNoteOther
                    ? workflow.details.viewLastNoteOther
                    : null,
                version: workflow.details.noteVersion,
                note_state_is_not: "draft",
                limit_one_per_provider_type: true,
                completed_date_time_exists: true,
                is_not_patient_no_show: true,
                note_types: validNoteTypes.join(","),
            };
            let lastCompletedNotesByOtherProvidersResponse =
                await api.shared.fetch_patient_progress_notes({
                    urlParams,
                    queryParams: lastCompletedNotesByOtherProvidersQueryParams,
                });

            const lastNotesOther = fetchNecessaryNotesByWorkflow(
                workflow.details,
                lastCompletedNotesByOtherProvidersResponse,
            );

            const cptOptions = this.getCPTOptions();
            const completedCalls = await getCompletedCalls(urlParams, (call) =>
                cptOptions.map((option) => option.value).includes(call.event_type),
            );

            const note = this.getEmptyNote(
                patient,
                lastNote,
                lastNotesOther,
                patientDiagnosis,
                event_type,
                this.props.profile,
                questionSet,
                workflow,
            );
            this.setState({
                patient,
                note,
                lastNote,
                lastNotesOther,
                patientDiagnosis,
                questionSet,
                completedCalls,
                event_type,
                workflow,
            });
        } catch (e) {
            console.log(e);
        }
    };

    getEmptyNote = (
        patient,
        lastNote,
        lastNotesOther,
        patientDiagnosis,
        eventType,
        provider,
        questionSet,
        workflow,
    ) => {
        const workflowDetails = workflow.details;
        const allottedTime = allottedTimeFactory(eventType);
        const callDetails = {
            callId: "",
            timestamp: moment().utc().format("YYYY-MM-DD HH:mm"),
            allotted_time: allottedTime,
            provider_role: provider.provider_type,
            event_type: eventType,
        };
        let note = createEmptyNote(callDetails, workflowDetails.noteVersion, patientDiagnosis);
        if (workflowDetails.noteEnricher) {
            note.content = workflowDetails.noteEnricher(
                patient,
                questionSet,
                workflowDetails.relevantPatientQuestionSections,
                { lastNote, lastNotesOther },
                patientDiagnosis,
            );
        }
        note.content.call_timer = formatAllottedTime(allottedTime);
        note.call_type = eventType;
        note.content.discharges = [];
        note.cp_credentials = provider?.cp_credentials ?? null;
        console.log(note);
        return note;
    };

    handleCallTypeChange = async (event_type) => {
        const data = {
            event_type: event_type,
        };
        const questionSet = await api.shared.get_patient_call_question_sets({ data });
        const workflow = _.cloneDeep(getCPTWorkflow(event_type, this.props.profile));
        const note = this.getEmptyNote(
            this.state.patient,
            this.state.lastNote,
            this.state.lastNotesOther,
            this.state.patientDiagnosis,
            event_type,
            this.props.profile,
            questionSet,
            workflow,
        );
        this.setState({
            note,
            questionSet,
            event_type,
            workflow,
        });
        return { note, workflow, questionSet };
    };

    editHandler = (key, value, nested = true) => {
        this.setState((prevState) => {
            return {
                note: updateNote(prevState.note, nested, key, value),
            };
        });
    };

    resetValidationHandler = (noteErrorKey, noteErrorValue) => {
        const noteErrors = { ...this.state.noteErrors };
        noteErrors[noteErrorKey] = noteErrorValue;
        this.setState({ noteErrors });
    };

    saveNoteHandler = async (note) => {
        try {
            const urlParams = { patientId: this.state.patientId };
            return api.provider.post_progress_note({ urlParams, data: note });
        } catch (e) {
            console.log(e);
        }
    };

    noteDraftSubmitHandler = async () => {
        if (!this.noteValidation(true)) {
            return;
        }
        let note = this.state.note;
        await this.saveNoteHandler(note);
        this.props.history.goBack(-1);
    };

    noteValidation = (isDraft = false) => {
        let note = this.state.note;
        const medications =
            note["content"] && note["content"]["medications"] ? note["content"]["medications"] : [];
        const noteErrors = {};
        if (!isDraft) {
            if (!note["ICD_10_axis_1_diagnosis"] || note["ICD_10_axis_1_diagnosis"].length === 0) {
                noteErrors["ICD_10_axis_1_diagnosis"] = "ICD-10 Primary Diagnosis is required";
            }
        }

        if (note["call_id"] === "") {
            noteErrors["call_id"] = "Call Linking is required";
        }

        if (note["session_to"] === "") {
            noteErrors["session_to"] = "Session To is required";
        }

        if (note["session_from"] === "") {
            noteErrors["session_from"] = "Session From is required";
        }

        if (this.props.profile.provider_type === PRESCRIBE && !note["service_location"]) {
            noteErrors["service_location"] = "Service Location is required";
        }

        if (medications.length > 0) {
            medications.forEach((medication) => {
                if (medication["medication_name"] === "") {
                    noteErrors["medication_name"] = "Medication Name is required";
                }

                if (medication["dosage"] === "") {
                    noteErrors["dosage"] = "Dosage is required";
                }
            });
        }

        this.setState({ noteErrors });
        return Object.keys(noteErrors).length === 0;
    };

    noteSubmitHandler = async () => {
        if (!this.noteValidation()) {
            return;
        }
        let note = this.state.note;
        note["signature"] = this.props.profile.name;
        note["completed_date_time"] = moment().format();
        note["note_state"] = "complete";
        await this.saveNoteHandler(note);
        this.props.history.goBack(-1);
    };

    render() {
        const { note, patient } = this.state;

        if (!note) {
            return <div />;
        }

        return (
            <>
                <EditNoteWrapper
                    patient={patient}
                    note={note}
                    noteDraftSubmitHandler={this.noteDraftSubmitHandler}
                    noteSubmitHandler={this.noteSubmitHandler}>
                    <NotesLinkCall
                        note={note}
                        cptOptions={this.getCPTOptions()}
                        handleCallTypeChange={this.handleCallTypeChange}
                        editHandler={this.editHandler}
                        errors={this.state.noteErrors}
                        completedCalls={this.state.completedCalls}
                        workflow={this.state.workflow}
                        patient={this.state.patient}
                        initialPatientQuestionSets={this.state.questionSet}
                        resetValidationHandler={this.resetValidationHandler}
                        lastNotesOther={this.state.lastNotesOther}
                    />
                    {noteV2Display(
                        note,
                        true,
                        this.editHandler,
                        { createEditView: true },
                        this.state.noteErrors,
                        true,
                        true,
                    )}
                </EditNoteWrapper>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};

export default connect(mapStateToProps)(
    withRouter(NewNoteV2__EXPERIMENTAL_GOALS_AND_OBJECTIVES_GUIDED_SESSION_DISABLED),
);
