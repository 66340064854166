// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import { VideoViewBase } from "../index";
import { ProviderWelcomeStep } from "./ProviderWorkflow/ProviderWelcomeStep";
import { api } from "../../../../APIRequests";
import Spinner from "../../../UI/Spinner";
import _ from "lodash";
import withRouter from "react-router-dom/es/withRouter";
import { connect } from "react-redux";
import {
    createEmptyNote,
} from "./ProviderWorkflow/WorkflowFunctions";
import moment from "moment";
import { PRESCRIBE } from "../../../Provider/ScheduleAvailability/constants";
import {
    checkInterventionAndPlanErrors,
} from "./ProviderWorkflow/InterventionAndPlan/InterventionAndPlanV2/InterventionAndPlanV2FormSchema";

export const isNoteNoShow = (note) => {
    if (!note.content) {
        return false;
    }

    if (note.content.patient_no_show != null) {
        return note.content.patient_no_show;
    }

    return false;
};

export const fetchNecessaryNotesByWorkflow = (workflowDetails, notes) => {
    let lastNotesOther = {};
    if (workflowDetails.viewLastNoteOther !== null && notes) {
        let otherNotes = notes.filter(
            (note) =>
                note.provider_type &&
                note.provider_type.startsWith(workflowDetails.viewLastNoteOther),
        );
        if (otherNotes && otherNotes.length > 0) {
            lastNotesOther[workflowDetails.viewLastNoteOther] = otherNotes[0];
        }
    }

    return lastNotesOther;
};

class ProviderVideoWorkflowStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            markerData: [],
            lastNote: null,
            currentStep: -1,
            workflow: this.props.workflow,
            fullScreen: false,
            noteErrors: {},
        };
    }

    async componentDidMount() {
        let callDetails = this.props.callDetails;
        let options = { params: { showLoader: false } };
        const urlParams = { patientId: callDetails.patient_username };
        let patientDetails = (await api.shared.fetch_patient_details({ urlParams, options })).user;

        let workflowDetails = this.props.workflow.details;
        let currentStep = workflowDetails.startingStep;

        let notes = [];
        let questionSet;
        let processedQuestionSet;
        let lastNotesOther = {};
        let lastNote = null;
        const validNoteTypes = [
            "Video Consultation",
            "Check-in",
            "Care Coordination",
            "Initial Clinical Assessment",
            "Care Communication",
            "Case Management",
            null,
        ];
        if (
            workflowDetails.viewLastNoteSelf ||
            workflowDetails.viewLastNoteOther !== null ||
            workflowDetails.createNote
        ) {
            const lastCompletedNoteByProviderIdQueryParams = {
                provider_id: callDetails.provider_username,
                version: workflowDetails.noteVersion,
                note_state_is_not: "draft",
                limit_one_per_provider_type: true,
                completed_date_time_exists: true,
                is_not_patient_no_show: true,
                note_types: validNoteTypes.join(","),
            };
            notes = await api.shared.fetch_patient_progress_notes({
                urlParams,
                queryParams: lastCompletedNoteByProviderIdQueryParams,
            });

            lastNote = notes ? notes[0] : null;

            const lastCompletedNotesByOtherProvidersQueryParams = {
                provider_type_begins_with: workflowDetails.viewLastNoteOther
                    ? workflowDetails.viewLastNoteOther
                    : null,
                version: workflowDetails.noteVersion,
                note_state_is_not: "draft",
                limit_one_per_provider_type: true,
                completed_date_time_exists: true,
                is_not_patient_no_show: true,
                note_types: validNoteTypes.join(","),
            };
            let lastCompletedNotesByOtherProvidersResponse =
                await api.shared.fetch_patient_progress_notes({
                    urlParams,
                    queryParams: lastCompletedNotesByOtherProvidersQueryParams,
                });

            lastNotesOther = fetchNecessaryNotesByWorkflow(
                workflowDetails,
                lastCompletedNotesByOtherProvidersResponse,
            );
            const data = {
                callId: callDetails["callId"],
            };
            questionSet = await api.shared.get_patient_call_question_sets({ data });
            processedQuestionSet = callDetails.patient_question_sets
                ? callDetails.patient_question_sets
                : questionSet;
        }

        let progressNote;
        let patientDiagnosis = null;
        if (workflowDetails.createNote) {
            let existingNotes = await api.shared.fetch_patient_progress_notes({
                urlParams,
                queryParams: {
                    call_id: callDetails.callId,
                },
            });
            let existingNote =
                existingNotes && existingNotes.length === 1 ? existingNotes[0] : null;
            patientDiagnosis = await api.shared.get_patient_diagnosis({ urlParams });
            if (existingNote) {
                progressNote = existingNote;
            } else {
                progressNote = createEmptyNote(
                    callDetails,
                    workflowDetails.noteVersion,
                    patientDiagnosis,
                );
            }

            if (workflowDetails.noteEnricher) {
                progressNote.content = workflowDetails.noteEnricher(
                    patientDetails,
                    processedQuestionSet,
                    workflowDetails.relevantPatientQuestionSections,
                    { existingNote, lastNote, lastNotesOther },
                    patientDiagnosis,
                );
            }

            if (existingNote && !existingNote.completed_date_time && !existingNote.signature) {
                progressNote = await this.saveNoteHandler(progressNote);
            } else if (!existingNote) {
                const urlParams = { patientId: this.props.callDetails.patient_username };
                progressNote = await api.provider.post_progress_note({
                    urlParams,
                    data: progressNote,
                });
            }
        } else if (workflowDetails.createClinicalAssessmentNote) {
            const existingClinicalAssessment = await api.shared.fetch_patient_progress_notes({
                urlParams,
                queryParams: {
                    provider_id: callDetails.provider_username,
                    note_types: "Initial Clinical Assessment",
                },
                options,
            });

            if (
                Array.isArray(existingClinicalAssessment) &&
                existingClinicalAssessment.length > 0
            ) {
                progressNote = existingClinicalAssessment[0];
            } else {
                progressNote = await api.provider.post_patient_clinical_assessment({
                    data: {},
                    urlParams: { patientId: this.props.callDetails.patient_username },
                    queryParams: { type: "initial" },
                });
            }
        }

        let addendums;
        if (lastNote) {
            const patientId = callDetails.patient_username;
            const urlParams = { patientId };

            const data = {
                note_id: lastNote.id,
                patient_id: patientId,
            };
            const addendumsResponse = await api.shared.fetch_note_addendums({ urlParams, data });
            addendums = addendumsResponse.addendums;
        }

        await this.setState({
            callDetails,
            patientDetails,
            notes,
            lastNote,
            lastNotesOther,
            addendums,
            workflowDetails,
            currentStep,
            progressNote,
            patientDiagnosis,
            refresh: true,
        });

        if (currentStep >= 0 && this.props.workflow.steps[currentStep].startCall) {
            if (!this.props.activeRoom) {
                await this.props.startCall();
            } else {
                this.props.attachRemoteTracks();
                this.props.attachLocalTracks();
            }
        }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            !prevProps.callDetails.patient_question_sets &&
            this.props.callDetails.patient_question_sets
        ) {
            const {
                workflowDetails,
                patientDetails,
                progressNote,
                lastNote,
                lastNotesOther,
                patientDiagnosis,
            } = this.state;
            let progressNoteCopy = _.cloneDeep(progressNote);

            if (workflowDetails.noteEnricher) {
                progressNoteCopy.content = workflowDetails.noteEnricher(
                    patientDetails,
                    this.props.callDetails.patient_question_sets,
                    workflowDetails.relevantPatientQuestionSections,
                    { existingNote: progressNote, lastNote, lastNotesOther },
                    patientDiagnosis,
                );
                await this.saveNoteHandler(progressNoteCopy);
            }

            await this.setState({
                callDetails: this.props.callDetails,
                progressNote: progressNoteCopy,
                refresh: false,
            });

            this.setState({
                refresh: true,
            });
        }
    }

    /**
     * changes the current step of the workflow
     *
     * @param {*} step the step number to jump to
     * @param {*} updatedProgressNote optional, passing in data here means
     * that the progress note saved in this components state should be ignored
     * and should be updated after the note is saved
     */
    jumpWorkflowStep = async (step, updatedProgressNote) => {
        let nextStep = step;

        await this.setState({
            currentStep: nextStep,
        });

        if (this.props.workflow.steps[nextStep].startCall) {
            if (!this.props.activeRoom) {
                await this.props.startCall();
            } else {
                this.props.attachRemoteTracks();
                this.props.attachLocalTracks();
            }
        }

        if (this.props.workflow.steps[nextStep].endCall && this.props.activeRoom) {
            await this.endCall();
        }

        if (this.props.workflow.steps[nextStep].saveNote) {
            const progressNote = updatedProgressNote ?? { ...this.state.progressNote };
            progressNote["content"]["call_timer"] = this.props.timer;
            if (!progressNote.completed_date_time && !progressNote.signature) {
                this.saveNoteHandler(progressNote);
                if (updatedProgressNote) {
                    this.setState({ progressNote });
                }
            }
        }
    };

    endCall = async () => {
        let lastStep = this.props.workflow.steps.length - 1;

        await this.setState({
            currentStep: lastStep,
        });

        await this.props.endCall(this.state.workflow.details.endCallShouldRoute);
    };

    /**
     * helper function that updates the content
     * of the note WITHOUT modifying state and returns the update
     *
     * @param {*} currentNote
     * @param {*} key
     * @param {*} value
     * @param {*} nested
     * @returns
     */
    updateNoteContent = (currentNote, key, value, nested = true) => {
        let clonedNote = _.cloneDeep(currentNote);

        if (nested) {
            clonedNote.content[key] = _.cloneDeep(value);
        } else {
            clonedNote[key] = _.cloneDeep(value);
        }

        return clonedNote;
    };

    noteEditHandler = (key, value, nested = true) => {
        this.setState((prevState) => {
            return {
                progressNote: this.updateNoteContent(prevState.progressNote, key, value, nested),
            };
        });
    };

    signNote = async () => {
        let { progressNote, callDetails } = this.state;
        progressNote["signature"] = callDetails?.provider_name;
        progressNote["completed_date_time"] = moment().format();
        await this.setState({ progressNote });
    };

    saveNoteHandler = async (progressNote) => {
        try {
            const urlParams = { patientId: this.props.callDetails.patient_username };
            return await api.provider.put_progress_note({ urlParams, data: progressNote });
        } catch (e) {
            console.log(e);
            this.props.history.push(this.props.authRedirectPath);
        }
    };

    noteDraftValidation = () => {
        const hasInterventionAndPlanError = checkInterventionAndPlanErrors(
            this.state.progressNote?.content,
        );

        return !hasInterventionAndPlanError;
    };

    noteDraftSubmitHandler = async () => {
        if (!this.noteDraftValidation()) {
            return;
        }
        let progressNote = this.state.progressNote;
        await this.saveNoteHandler(progressNote);
        this.props.history.push(this.props.authRedirectPath);
    };

    noteValidation = () => {
        let progressNote = this.state.progressNote;
        const noteErrors = {};
        if (
            !progressNote["ICD_10_axis_1_diagnosis"] ||
            progressNote["ICD_10_axis_1_diagnosis"].length === 0
        ) {
            noteErrors["ICD_10_axis_1_diagnosis"] = "ICD-10 Primary Diagnosis is required";
        }

        if (!progressNote["call_type"]) {
            noteErrors["call_type"] = "CPT Code is required";
        }

        if (progressNote.provider_type === PRESCRIBE && !progressNote["service_location"]) {
            noteErrors["service_location"] = "Service Location is required";
        }

        if (checkInterventionAndPlanErrors(progressNote?.content)) {
            // the error message here has no impact, the error is displayed by
            // formik
            noteErrors["goals_objectives_progress_v2"] = "goals_objectives_progress_v2_error";
        }

        this.setState({ noteErrors });
        return Object.keys(noteErrors).length === 0;
    };

    noteSubmitHandler = async () => {
        if (!this.noteValidation()) {
            return;
        }
        await this.signNote();
        let progressNote = this.state.progressNote;
        progressNote["note_state"] = "complete";
        await this.saveNoteHandler(progressNote);
        this.props.history.push(this.props.authRedirectPath);
    };

    generateHeader = () => {
        let header = null;
        if (this.props.workflow.steps.length !== this.state.currentStep) {
            let headerText = this.props.workflow.steps[this.state.currentStep].header;
            if (headerText) {
                header = <div className="fw-bold text-white fs-14 fs-lg-18">{headerText}</div>;
            }
        }

        return header;
    };

    generateStep = () => {
        let content = <div />;
        if (this.props.workflow.steps.length !== this.state.currentStep) {
            let step = this.props.workflow.steps[this.state.currentStep];
            let componentProps = {
                step: step,
                props: this.props,
                state: this.state,
                jumpWorkflowStep: this.jumpWorkflowStep,
                endCall: this.endCall,
                noteEditHandler: this.noteEditHandler,
                updateNoteContent: this.updateNoteContent,
                noteSubmitHandler: this.noteSubmitHandler,
                noteDraftSubmitHandler: this.noteDraftSubmitHandler,
            };
            content = <step.component {...componentProps} />;
        }

        return content;
    };

    render() {
        if (!this.state.callDetails) {
            return <Spinner />;
        }

        if (this.state.currentStep === -1) {
            return <ProviderWelcomeStep startWorkflow={this.jumpWorkflowStep} {...this.props} />;
        }

        return (
            <VideoViewBase
                header={this.generateHeader()}
                content={this.generateStep()}
                workflow={this.props.workflow}
                currentStep={this.state.currentStep}
                {...this.props}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authRedirectPath: state.auth.authRedirectPath,
    };
};

export default withRouter(connect(mapStateToProps)(ProviderVideoWorkflowStep));
