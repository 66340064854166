import { Form } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { api } from "../../../../../APIRequests";
import {
    Button,
    ButtonSizes,
    ButtonTypes,
    CustomForm,
    Modal,
} from "../../../../../component-library";
import { CharacterCounter } from "../../../../../component-library/CharacterCounter/CharacterCounter";
import { FormRadioGroup } from "../../../../../component-library/FormFields/FormRadioGroup/FormRadioGroup";
import { FormSelect } from "../../../../../component-library/FormFields/FormSelect/FormSelect";
import { FormTextInput } from "../../../../../component-library/FormFields/FormTextInput/FormTextInput";
import "../../../../../component-library/Link/styles.scss";
import { ICON_SIZES, ICON_TYPES } from "../../../../../constants/icons";
import { hideLoader, showLoader } from "../../../../../redux/actions/loader";
import { CloseIcon } from "../../../../Icons/CloseIcon";
import "./styles.scss";
import { addToast, toastMessageTypes } from "../../../../../redux/actions/toaster";

const OTHER_TEXT = "Other (Specify...)";

export const MODAL_MODES = {
    EMPTY: "empty",
    ADD: "add",
    UPDATE: "update",
};

export const MODALITY_ALLOWED_ROLES = {
    BHS: "Behavioral Specialist",
    PEER: "Peer",
    CASE: "Case Manager",
    PRESCRIBER: "Prescriber",
};

const DEFAULT_APPOINTMENT_ROLES = [MODALITY_ALLOWED_ROLES.BHS, MODALITY_ALLOWED_ROLES.PRESCRIBER];

const MODALITY_FORM_VALIDATION = yup.object().shape({
    modality: yup.string().when("provider_type", {
        is: MODALITY_ALLOWED_ROLES.BHS,
        then: yup.string().required("Modality is required"),
    }),
    modality_other: yup.string().when("modality", {
        is: OTHER_TEXT,
        then: yup
            .string()
            .matches(/^[a-zA-Z0-9, ']+$/, "Special characters are not allowed")
            .required(`Modality specification is required when selecting "${OTHER_TEXT}"`),
    }),
    frequency: yup.string().required("Modality frequency is required"),
    default_appointment_type: yup.string().when("provider_type", {
        is: (value) => DEFAULT_APPOINTMENT_ROLES.includes(value),
        then: yup.string().required("Appointment duration is required"),
    }),
});

const formatOptions = (optionsList) => {
    const res = [];
    optionsList.forEach((option) => {
        res.push({ label: option, value: option });
    });
    return res;
};

const formatDefaultAppointmentOptions = (optionsObject) => {
    const res = [];
    Object.keys(optionsObject).forEach((key) => {
        res.push({ label: optionsObject[key].text, value: key });
    });
    return res;
};

export const CareTeamModalityModal = (props) => {
    const { mode, careTeamMember, options, patientId } = props;
    const dispatch = useDispatch();

    const isBHS = careTeamMember?.type === MODALITY_ALLOWED_ROLES.BHS;
    const titleOperation = mode === MODAL_MODES.ADD ? "Select" : "Edit";
    const buttonOperation = mode === MODAL_MODES.ADD ? "Add" : "Save";
    const bhsTitle = `
        ${titleOperation} 
        ${mode === MODAL_MODES.ADD ? "a " : ""} 
        primary treatment modality`;
    const regularTitle = `${titleOperation} modality details`;
    const title = isBHS ? bhsTitle : regularTitle;

    const renderForm = (formikProps) => {
        const [showModalityOther, setShowModalityOther] = useState(
            careTeamMember?.modality === OTHER_TEXT,
        );

        useEffect(() => {
            if (formikProps.values.modality === OTHER_TEXT) {
                setShowModalityOther(true);
            } else {
                setShowModalityOther(false);
                formikProps.setFieldValue("modality_other", "");
            }
        }, [formikProps.values.modality]);

        return (
            <Form>
                <div className="CareTeamModalityModal--modal_form">
                    {isBHS && (
                        <FormRadioGroup
                            id="CareTeamModalityModal_ModalityInput"
                            className="AdvancedCareActivityForm-form_field"
                            title="Modality"
                            name="modality"
                            options={formatOptions(options.modality[MODALITY_ALLOWED_ROLES.BHS])}
                            formikProps={formikProps}
                            triggerErrorOnChange
                        />
                    )}
                    {isBHS && showModalityOther && (
                        <div className="CareTeamModalityModal--other_input_container">
                            <FormTextInput
                                id="CareTeamModalityModal_ModalityOtherInput"
                                className="AdvancedCareActivityForm-form_field"
                                title="Specify Modality"
                                name="modality_other"
                                formikProps={formikProps}
                                maxLength={250}
                                triggerErrorOnChange
                            />
                            <CharacterCounter
                                className={"CareTeamModalityModal--input_character_counter"}
                                length={formikProps.values?.modality_other?.length}
                                characterLimit={250}
                            />
                        </div>
                    )}
                    {!isBHS && (
                        <div data-public className="FormField-header_container">
                            <h3 className="FormField-title">Modality</h3>
                            <p className="text-break">{formikProps.values.modality}</p>
                        </div>
                    )}
                    <FormSelect
                        id="CareTeamModalityModal_FrequencyInput"
                        className={
                            "AdvancedCareActivityForm-form_field CareTeamModalityModal--select"
                        }
                        title="Modality Frequency"
                        subTitle="How often you plan to meet with the patient"
                        placeholder="Select"
                        name="frequency"
                        options={formatOptions(options.frequency)}
                        formikProps={formikProps}
                        triggerErrorOnChange
                    />
                    {DEFAULT_APPOINTMENT_ROLES.includes(careTeamMember?.type) && (
                        <FormSelect
                            id="CareTeamModalityModal_DefaultAppointmentTypeInput"
                            className={
                                "AdvancedCareActivityForm-form_field CareTeamModalityModal--select"
                            }
                            title="Appointment Duration"
                            subTitle="Sets this patient's self-scheduling duration without affecting options for you and other providers"
                            placeholder="Select"
                            name="default_appointment_type"
                            options={formatDefaultAppointmentOptions(
                                options.default_appointment_type[careTeamMember?.type],
                            )}
                            formikProps={formikProps}
                            triggerErrorOnChange
                        />
                    )}
                    <div className="AdvancedCareActivityForm-button_wrapper">
                        <Button
                            id="CareTeamModalityModal_SubmitButton"
                            type="submit"
                            buttonSize={ButtonSizes.medium}
                            buttonType={ButtonTypes.primaryV2}>
                            {buttonOperation}
                        </Button>
                        <Button
                            id="CareTeamModalityModal_CancelButton"
                            type="button"
                            onClick={() => {
                                formikProps.resetForm();
                                props.onClose();
                            }}
                            buttonType={ButtonTypes.primaryOutlineV2}
                            buttonSize={ButtonSizes.medium}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Form>
        );
    };

    return (
        <Modal
            className="CareTeamModalityModal--modal"
            show={true}
            cardClassName="CareTeamModalityModal--dialog">
            <CloseIcon
                onClick={props.onClose}
                className={"CareTeamModalityModal--modal_close_icon"}
                iconSize={ICON_SIZES.SMALL}
                iconType={ICON_TYPES.OUTLINE}
            />
            <div className="CareTeamModalityModal--modal_internal">
                <div className="CareTeamModalityModal--modal_header">
                    <h1 className="CareTeamModalityModal--modal_title">{title}</h1>
                </div>
                {careTeamMember && (
                    <CustomForm
                        initialValues={{
                            provider_type: careTeamMember.type,
                            modality:
                                careTeamMember?.modality ||
                                options.modality[careTeamMember?.type][0],
                            modality_other: careTeamMember?.modality_other,
                            frequency: careTeamMember?.frequency,
                            default_appointment_type: careTeamMember?.default_appointment_type,
                        }}
                        validationSchema={MODALITY_FORM_VALIDATION}
                        validateOnChange={true}
                        validateOnBlur={true}
                        render={renderForm}
                        onSubmit={async (formData) => {
                            const data = {
                                modality: formData.modality,
                                frequency: formData.frequency,
                                modality_other: formData?.modality_other,
                                default_appointment_type: formData.default_appointment_type,
                                provider_id: careTeamMember.provider_id,
                            };
                            dispatch(showLoader(true));
                            await api.carePlan.change_modality({
                                data,
                                urlParams: { patientId: patientId },
                                params: { showLoader },
                            });
                            await props.onModalSubmission();
                            dispatch(hideLoader());
                            if (mode === MODAL_MODES.UPDATE) {
                                dispatch(
                                    addToast({
                                        message: "Updated",
                                        messageType: toastMessageTypes.success_v2,
                                        dismissTimeout: 1000,
                                    }),
                                );
                            }
                        }}
                    />
                )}
            </div>
        </Modal>
    );
};
