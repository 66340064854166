// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import { useLocation } from "react-router-dom";
import NavMenuItem from "../../NavMenuItem";
import "../../NavMenuItem/styles.scss";

const RouteSection = (props) => {
    const location = useLocation();
    const options = props.config.options.map((option) => {
        if (option.mobileOnly) {
            return null;
        }

        if (option.label === "Search") {
            return (
                <li
                    key={option.label}
                    className="d-flex align-items-center justify-content-center h-100 flex-column flex-lg-row text-nowrap cursor search"
                    onClick={props.handleClickSearch}>
                    <a className="NavAnchor search-label d-flex flex-column flex-lg-row">
                        {option.iconComponent}
                        <div className="NavMenuLabelWrapper d-flex justify-content-center align-items-center">
                            {option.label}
                        </div>
                    </a>
                </li>
            );
        }

        if (option.iconComponent) {
            return (
                <NavMenuItem key={option.label} route={option.route} testId={`NavMenuItem_${option.label}`}>
                    {location.pathname.includes(option.route)
                        ? option.activeIconComponent
                        : option.iconComponent}
                    <div className="NavMenuLabelWrapper">{option.label}</div>
                    {props.totalUnreadMessages > 0 && option.label === "Messages" && (
                        <div className={"messagesNotification"}></div>
                    )}
                </NavMenuItem>
            );
        } else if (option.icon) {
            return (
                <NavMenuItem key={option.label} route={option.route}>
                    <span className={"mr-2 " + option.icon} alt=""></span>
                    {option.label}
                </NavMenuItem>
            );
        } else {
            return (
                <NavMenuItem key={option.label} route={option.route}>
                    {option.label}
                </NavMenuItem>
            );
        }
    });

    return (
        <ul className="Toolbar__menu d-flex justify-content-between h-100 w-100">
            {options}
            <li />
        </ul>
    );
};

export default RouteSection;
