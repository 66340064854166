import moment from "moment/moment";
import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    ChevronDownIcon,
    ChevronRightIcon,
    EnvelopeIcon,
    Text,
    TextTypes,
    UserIcon,
} from "../../../../../component-library";
import "../../../../../component-library/Link/styles.scss";
import { admin_bp } from "../../../../../config/roles";
import { getUsernameFromToken, isAuthorized } from "../../../../../redux/actions/auth";
import { addToast } from "../../../../../redux/actions/toaster";
import { useModalityText } from "../../../../../utils/hooks/useCarePlan";
import { copyToClipboard } from "../../../../../utils/webAPIUtils";
import { CarePlanCard } from "../CarePlanCard";
import { CarePlanIconButton } from "../CarePlanIconButton";
import { CareTeamMemberCard } from "./CareTeamMemberCard";
import { CareTeamModalityModal, MODAL_MODES } from "./CareTeamModalityModal";
import "./styles.scss";
import FeatureGate from "../../../../Common/FeatureGate/FeatureGate";
import { EXPERIMENTAL_MODALITY_FREQUENCY_DURATION } from "../../../../../constants/featureFlags";

export const checkIfProviderOnCareTeam = (provider, careTeam) => {
    for (let i = 0; i < careTeam.length; i++) {
        if (careTeam[i]?.provider_id === provider?.username) {
            return true;
        }
    }
    return false;
};

// this exists to be flexible to other potential
// labels that may arise
const tagTypeMap = {
    "Behavioral Specialist": "internal",
    Prescriber: "internal",
    "Case Manager": "internal",
    Peer: "internal",
    "Intake Behavioral Specialist": "internal",

    PCP: "external",
    "Care Manager": "external",
};

const CareTeamCard = ({ patientId, careTeamMembers, refreshCareTeam, profile }) => {
    const privateFlags = useSelector((state) => state.flags.privateFlags);
    const privateFlagsHaveLoaded = useSelector((state) => state.flags.privateFlagsHaveLoaded);
    const activeCareTeamMembers = careTeamMembers.filter(
        (careTeamMember) => !careTeamMember.is_deleted,
    );
    const activeCareTeamMembersById = activeCareTeamMembers.reduce((obj, item) => {
        obj[item["provider_id"]] = item;
        return obj;
    }, {});
    const inactiveCareTeamMembers = careTeamMembers
        .filter(
            (careTeamMember) =>
                careTeamMember.is_deleted &&
                // exclude care team members that are still active (this could be old records from a provider email change)
                !activeCareTeamMembersById.hasOwnProperty(careTeamMember.provider_id),
        )
        .sort(
            (a, b) =>
                new Date(b.is_deleted_modified_timestamp) -
                new Date(a.is_deleted_modified_timestamp),
        );
    const [showHistory, setShowHistory] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();
    const isCurrentUserAdmin =
        isAuthorized("admin:customer_success_executive") ||
        isAuthorized("admin:customer_success_manager") ||
        isAuthorized("admin:admin");

    const internalCopyToClipboard = (text) => {
        copyToClipboard(text, (arg) => {
            dispatch(addToast(arg));
        });
    };

    const internalCareTeamMembers = careTeamMembers
        .map((member) => {
            if (tagTypeMap[member.type] === "internal") {
                return member.email;
            }
        })
        .filter((value) => value !== undefined);

    // Modality modal controls
    const modalityOptions = useModalityText({ patientId: patientId });
    const [showModal, setShowModal] = useState(false);
    const [modalMode, setModalMode] = useState(MODAL_MODES.EMPTY);
    const [selectedCareTeamMember, setSelectedCareTeamMember] = useState(null);
    const onCloseModal = () => {
        setShowModal(false);
        setSelectedCareTeamMember(null);
        setModalMode(MODAL_MODES.EMPTY);
    };

    const onModalSubmission = async () => {
        await refreshCareTeam();
        onCloseModal();
    };

    const onSelectModalityDetails = (careTeamMember) => {
        setModalMode(MODAL_MODES.ADD);
        setSelectedCareTeamMember(careTeamMember);
        setShowModal(true);
    };

    const onUpdateModalityDetails = (careTeamMember) => {
        setModalMode(MODAL_MODES.UPDATE);
        setSelectedCareTeamMember(careTeamMember);
        setShowModal(true);
    };

    const renderActiveCareTeamMember = (careTeamMember) => {
        const canScheduleForSelf =
            // intake, BHS and care managers
            (isAuthorized("provider:behavioral") &&
                careTeamMember.provider_id === getUsernameFromToken()) ||
            (isAuthorized("provider:prescribe") &&
                careTeamMember.provider_id === getUsernameFromToken());

        const canScheduleOnBehalfOf =
            ((isCurrentUserAdmin ||
                isAuthorized("provider:peer") ||
                isAuthorized("provider:case_manager")) &&
                ["Prescriber", "Behavioral Specialist", "Intake Behavioral Specialist"].includes(
                    careTeamMember.type,
                )) ||
            // intake, BHS and care managers
            (isAuthorized("provider:behavioral") &&
                ["Prescriber", "Behavioral Specialist"].includes(careTeamMember.type));

        const canSchedule = canScheduleForSelf || canScheduleOnBehalfOf;
        return (
            <CareTeamMemberCard
                key={careTeamMember.name}
                profile={profile}
                careTeamMember={careTeamMember}
                onSelectModalityDetails={onSelectModalityDetails}
                onUpdateModalityDetails={onUpdateModalityDetails}
                tagType={tagTypeMap[careTeamMember.type] ?? "internal"}
                canSchedule={canSchedule}
                onSchedule={() => {
                    // the onSchedule function can only be reached if
                    // canSchedule is true so each condition here is inherently
                    // && with canSchedule
                    // we need to check self and behalf of again because
                    // we want to make sure each case is handled correctly

                    if (canScheduleForSelf) {
                        history.push("/app/schedule");
                    }
                    // new schedule on behalf of view
                    else if (
                        canScheduleOnBehalfOf &&
                        (isAuthorized("provider:peer") || isAuthorized("provider:case_manager"))
                    ) {
                        history.push(
                            `/app/schedule/${careTeamMember.provider_id}?patientId=${patientId}`,
                        );
                    }
                    // old schedule on behalf of view
                    else if (canScheduleOnBehalfOf) {
                        history.push(
                            "/app/schedule/" +
                                careTeamMember.provider_id +
                                "?patientId=" +
                                patientId +
                                "&providerName=" +
                                careTeamMember.name +
                                "&providerType=" +
                                careTeamMember.type,
                        );
                    }
                }}
                onEnvelope={() => internalCopyToClipboard(careTeamMember.email)}
            />
        );
    };

    const renderInactiveCareTeamMember = (careTeamMember) => {
        let careTeamRole = careTeamMember.type;
        const careTeamMemberType = tagTypeMap[careTeamRole] ?? "internal";
        const careTeamMemberTypeLabel =
            careTeamMemberType === "internal" ? "aptihealth" : "External";
        const isDeletedModifiedTimestamp = careTeamMember.is_deleted_modified_timestamp;
        if (isDeletedModifiedTimestamp) {
            careTeamRole += ` until ${moment
                .utc(isDeletedModifiedTimestamp)
                .local()
                .format("M/D/YYYY")}`;
        }
        return (
            <div>
                <div>
                    <Text type={TextTypes.light} className={"fs-16 fw-bold"}>
                        {careTeamMember.name}
                    </Text>
                </div>
                <div>
                    <Text type={TextTypes.light}>{careTeamMemberTypeLabel}</Text>
                </div>
                <div>
                    <Text type={TextTypes.light}>{careTeamRole}</Text>
                </div>
                {careTeamMember?.modality && (
                    <FeatureGate
                        defaultFlagBehavior={true}
                        flagName={EXPERIMENTAL_MODALITY_FREQUENCY_DURATION}
                        enabled={privateFlags?.[EXPERIMENTAL_MODALITY_FREQUENCY_DURATION]}
                        hasLoaded={privateFlagsHaveLoaded}
                        alternatedFlagDisabledBehavior={<></>}>
                        <div className="CareTeamCard--modality_history_container">
                            <Text type={TextTypes.light} className={"text-break"}>
                                {careTeamMember?.modality_other || careTeamMember.modality}
                            </Text>
                            <div>
                                <Text type={TextTypes.light} className="d-inline">
                                    {careTeamMember.frequency}
                                </Text>
                                {careTeamMember.default_appointment_type && (
                                    <>
                                        <Text type={TextTypes.light} className="d-inline">
                                            {" "}
                                            &mdash;{" "}
                                        </Text>
                                        <Text type={TextTypes.light} className="d-inline">
                                            {careTeamMember.default_appointment_time} Min
                                        </Text>
                                    </>
                                )}
                            </div>
                        </div>
                    </FeatureGate>
                )}
            </div>
        );
    };

    return (
        <CarePlanCard
            title="Care Team"
            className="CareTeamCard--container"
            actions={
                <>
                    {internalCareTeamMembers.length > 0 && (
                        <CarePlanIconButton
                            onClick={() => {
                                internalCopyToClipboard(internalCareTeamMembers.join("; "));
                            }}
                            text="Copy aptihealth Emails"
                            Icon={EnvelopeIcon}
                            iconButtonClassName="CareTeamCard--icon_button"
                            iconClassName="CareTeamCard--icon"
                        />
                    )}

                    {!isAuthorized("provider:pcp") && !isAuthorized(admin_bp) && (
                        <CarePlanIconButton
                            onClick={() => {
                                history.push({
                                    pathname: `/app/patient/${patientId}/care_team`,
                                });
                            }}
                            text="Manage Care Team"
                            Icon={UserIcon}
                            iconButtonClassName="CareTeamCard--icon_button"
                            iconClassName="CareTeamCard--icon"
                        />
                    )}
                </>
            }>
            <FeatureGate
                defaultFlagBehavior={true}
                flagName={EXPERIMENTAL_MODALITY_FREQUENCY_DURATION}
                enabled={privateFlags?.[EXPERIMENTAL_MODALITY_FREQUENCY_DURATION]}
                hasLoaded={privateFlagsHaveLoaded}
                alternatedFlagDisabledBehavior={<></>}>
                {showModal && (
                    <CareTeamModalityModal
                        onClose={onCloseModal}
                        onModalSubmission={onModalSubmission}
                        mode={modalMode}
                        careTeamMember={selectedCareTeamMember}
                        options={modalityOptions}
                        patientId={patientId}
                    />
                )}
            </FeatureGate>

            <div className="CareTeamCard--members_container">
                {activeCareTeamMembers.map((careTeamMember) =>
                    renderActiveCareTeamMember(careTeamMember),
                )}
            </div>

            {inactiveCareTeamMembers.length > 0 && (
                <div>
                    <CarePlanIconButton
                        text={!showHistory ? "Show Past Members" : "Hide Past Members"}
                        Icon={!showHistory ? ChevronRightIcon : ChevronDownIcon}
                        iconButtonClassName="CareTeamCard--icon_button"
                        iconClassName="CareTeamCard--icon"
                        onClick={() => {
                            setShowHistory(!showHistory);
                        }}
                    />
                </div>
            )}

            {showHistory && (
                <>
                    <div className="CareTeamCard--members_container">
                        {inactiveCareTeamMembers.map((careTeamMember) =>
                            renderInactiveCareTeamMember(careTeamMember),
                        )}
                    </div>
                    <div>
                        {/*Release 9.40.0 PR to master: https://github.com/aptihealth/aptihealth-backend/pull/3945/files#diff-6217c699eeaa8cc760d63b3d48c77ea2c1e927b85b5cebc9ec0b84698920f13bR32*/}
                        <Text type={TextTypes.light}>
                            Note that care team history before 12/21/2023 was not tracked.
                        </Text>
                    </div>
                </>
            )}
        </CarePlanCard>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};

export default connect(mapStateToProps, null)(CareTeamCard);
