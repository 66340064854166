import React from "react";
import { useSelector } from "react-redux";
import { CalendarIcon, EnvelopeIcon } from "../../../../../component-library";
import { EXPERIMENTAL_MODALITY_FREQUENCY_DURATION } from "../../../../../constants/featureFlags";
import { ICON_SIZES, ICON_TYPES } from "../../../../../constants/icons";
import FeatureGate from "../../../../Common/FeatureGate/FeatureGate";
import LinkIcon, { LinkIconColor } from "../../../../UI/LinkIcon";
import { MODALITY_ALLOWED_ROLES } from "./CareTeamModalityModal";
import { isAuthorized } from "../../../../../redux/actions/auth";
import { admin_cse } from "../../../../../config/roles";

const CareTeamMemberCardTag = ({ tagType }) => {
    const tag = tagType === "external" ? "External" : "aptihealth";
    const containerClass =
        tagType === "external"
            ? "CareTeamMemberCardTag--external"
            : "CareTeamMemberCardTag--internal";

    return (
        <div className={containerClass}>
            <p className="CareTeamMemberCardTag--text">{tag}</p>
        </div>
    );
};

export const CareTeamMemberCard = ({
    careTeamMember,
    profile,
    onSelectModalityDetails,
    onUpdateModalityDetails,
    tagType,
    canSchedule,
    onSchedule,
    onEnvelope,
}) => {
    const privateFlags = useSelector((state) => state.flags.privateFlags);
    const privateFlagsHaveLoaded = useSelector((state) => state.flags.privateFlagsHaveLoaded);

    const hasModality = !!careTeamMember.modality;
    const canEdit = isAuthorized(admin_cse)
        ? Boolean(profile?.is_edit_care_plan_enabled)
        : careTeamMember?.provider_id === profile?.username;
    const isModalityRole = Object.values(MODALITY_ALLOWED_ROLES).includes(careTeamMember.type);
    const shouldShowModality = isModalityRole && (hasModality || canEdit);

    return (
        <div className="CareTeamMemberCard--container">
            <div className="CareTeamMemberCard--details_container">
                <div className="CareTeamMemberCard--details">
                    <p className="CareTeamMemberCard--name">{careTeamMember.name}</p>
                    <CareTeamMemberCardTag tagType={tagType} />
                    <p className="CareTeamMemberCard--provider_type CareTeamMemberCard--subtext">
                        {careTeamMember.type}
                    </p>
                </div>
                <div className="CareTeamMemberCard--action_container">
                    {canSchedule && (
                        <LinkIcon
                            label={"Schedule"}
                            icon={
                                <CalendarIcon
                                    className="CareTeamMemberCard--icon"
                                    iconSize={ICON_SIZES.SMALL}
                                    iconType={ICON_TYPES.OUTLINE}
                                />
                            }
                            onClick={onSchedule}
                            color={LinkIconColor.TEAL}
                        />
                    )}
                    <LinkIcon
                        label={"Copy Email"}
                        icon={
                            <EnvelopeIcon
                                className="CareTeamMemberCard--icon"
                                iconSize={ICON_SIZES.SMALL}
                                iconType={ICON_TYPES.OUTLINE}
                            />
                        }
                        onClick={onEnvelope}
                        color={LinkIconColor.TEAL}
                    />
                </div>
            </div>
            <FeatureGate
                defaultFlagBehavior={true}
                flagName={EXPERIMENTAL_MODALITY_FREQUENCY_DURATION}
                enabled={privateFlags?.[EXPERIMENTAL_MODALITY_FREQUENCY_DURATION]}
                hasLoaded={privateFlagsHaveLoaded}
                alternatedFlagDisabledBehavior={<></>}>
                {shouldShowModality && (
                    <div className="CareTeamMemberCard--modality_container">
                        {hasModality && (
                            <>
                                <p className="CareTeamMemberCard--modality CareTeamMemberCard--subtext text-break">
                                    {careTeamMember?.modality_other || careTeamMember.modality}
                                </p>
                                <div className="CareTeamMemberCard--frequency-appointment-container">
                                    <p className="CareTeamMemberCard--frequency CareTeamMemberCard--subtext d-inline">
                                        {careTeamMember.frequency}
                                    </p>
                                    {careTeamMember.default_appointment_type && (
                                        <>
                                            <span className="CareTeamMemberCard--subtext d-inline">
                                                {" "}
                                                &mdash;{" "}
                                            </span>
                                            <p className="CareTeamMemberCard--default-appointment CareTeamMemberCard--subtext d-inline">
                                                {careTeamMember.default_appointment_time} Min
                                            </p>
                                        </>
                                    )}
                                    {canEdit && (
                                        <>
                                            <span className="CareTeamMemberCard--subtext d-inline">
                                                {" "}
                                                •{" "}
                                            </span>
                                            <button
                                                onClick={() =>
                                                    onUpdateModalityDetails(careTeamMember)
                                                }
                                                className="CarePlanIconButton--icon_button CareTeamMemberCard--edit_modality_details d-inline">
                                                <p className="apti-LinkV2">Edit</p>
                                            </button>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                        {!hasModality && canEdit && (
                            <button
                                onClick={() => onSelectModalityDetails(careTeamMember)}
                                className="CarePlanIconButton--icon_button CareTeamMemberCard--select_modality_details">
                                <p className="apti-LinkV2">Select Modality Details</p>
                            </button>
                        )}
                    </div>
                )}
            </FeatureGate>
        </div>
    );
};
