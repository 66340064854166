import React from "react";
import "./styles.scss";
import moment from "moment/moment";
import { FormTextArea } from "../../../../../../../component-library/FormFields/FormTextArea/FormTextArea";
import { CharacterCounter } from "../../../../../../../component-library/CharacterCounter/CharacterCounter";
import { INTERVENTION_AND_PLAN_CHARACTER_LIMIT_ERROR } from "./InterventionAndPlanV2FormSchema";

export const InterventionAndPlanV2GoalField = ({
    formikProps,
    goalNumber,
    goalAndObjectiveProgress,
}) => {
    const goalNumberValue = Number(goalNumber.split("_")[1]);
    const goalText = goalAndObjectiveProgress.goal_other_text ?? goalAndObjectiveProgress.goal_text;

    return (
        <div
            key={goalAndObjectiveProgress.goal_text_id}
            className="InterventionAndPlanV2GoalField--container">
            <p className="InterventionAndPlanV2GoalField--title">
                {goalNumberValue}. {goalText}
            </p>
            <div className="InterventionAndPlanV2GoalField--objective_container">
                {goalAndObjectiveProgress.objectives.map((objective) => {
                    const objectiveText =
                        objective.objective_other_text ?? objective.objective_text;
                    return (
                        <p
                            key={objective.objective_text_id}
                            className="InterventionAndPlanV2GoalField--objective_text">
                            {objectiveText} • Target:{" "}
                            {moment(objective.objective_target_timestamp).format("MM/DD/YY")}
                        </p>
                    );
                })}
            </div>
            <FormTextArea
                inputClassName="InterventionAndPlanV2GoalField--intervention_and_plan_input"
                placeholder="Describe intervention and plans..."
                name={`${goalNumber}.interventions_and_plans`}
                formikProps={formikProps}
                triggerErrorOnChange
                testId={`InterventionAndPlanV2GoalField_field_${goalNumber}`}
            />
            {formikProps.errors?.[goalNumber]?.interventions_and_plans ===
                INTERVENTION_AND_PLAN_CHARACTER_LIMIT_ERROR && (
                <CharacterCounter
                    length={formikProps.values[goalNumber].interventions_and_plans.length}
                    characterLimit={4000}
                />
            )}
        </div>
    );
};
