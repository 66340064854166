import React from "react";
import "./styles.scss";
import { Card, CustomForm } from "../../../../../../../component-library";
import { InterventionAndPlanV2GoalField } from "./InterventionAndPlanV2GoalField";
import { INTERVENTION_AND_PLAN_SCHEMA } from "./InterventionAndPlanV2FormSchema";
import { EmptyCard } from "../../../../../../../component-library/EmptyCard/EmptyCard";
import { WorkflowStepTransitionButtons } from "../../WorkflowStepTransitionButtons";

export const InterventionAndPlanV2 = ({
    progressNote,
    updateNoteContent,
    goalsObjectivesProgress,
    workflow,
    jumpWorkflowStep,
    currentWorkflowStep,
}) => {
    const hasGoalsAndObjectives = Boolean(Object.keys(goalsObjectivesProgress).length);

    const renderForm = (formikProps) => {
        const hasErrors = Boolean(Object.keys(formikProps.errors).length);

        return (
            <>
                {Object.keys(formikProps.values).map((goalNumber, index) => {
                    return (
                        <>
                            <InterventionAndPlanV2GoalField
                                formikProps={formikProps}
                                goalAndObjectiveProgress={formikProps.values[goalNumber]}
                                goalNumber={goalNumber}
                            />
                            {/* hide hr for last element in the list */}
                            {index !== Object.keys(formikProps.values).length - 1 && (
                                <div className="InterventionAndPlanV2--hr" />
                            )}
                        </>
                    );
                })}

                <WorkflowStepTransitionButtons
                    className="InterventionAndPlanV2--transition_buttons"
                    onNextStep={() => {
                        if (hasErrors) {
                            return;
                        }

                        const newNote = updateNoteContent(
                            progressNote,
                            "goals_objectives_progress_v2",
                            formikProps.values,
                        );
                        jumpWorkflowStep(currentWorkflowStep + 1, newNote);
                    }}
                    onPreviousStep={() => {
                        if (hasErrors) {
                            return;
                        }

                        const newNote = updateNoteContent(
                            progressNote,
                            "goals_objectives_progress_v2",
                            formikProps.values,
                        );
                        jumpWorkflowStep(currentWorkflowStep - 1, newNote);
                    }}
                    currentStep={currentWorkflowStep}
                    workflow={workflow}
                />
            </>
        );
    };

    return (
        <Card className="InterventionAndPlanV2--container">
            {!hasGoalsAndObjectives && (
                <>
                    <EmptyCard
                        text={
                            "No goals or objectives in the Care Plan. New goals or objectives will appear in the next session after being added to the Care Plan."
                        }
                    />

                    <WorkflowStepTransitionButtons
                        className="InterventionAndPlanV2--transition_buttons"
                        onNextStep={() => {
                            jumpWorkflowStep(currentWorkflowStep + 1);
                        }}
                        onPreviousStep={() => {
                            jumpWorkflowStep(currentWorkflowStep - 1);
                        }}
                        currentStep={currentWorkflowStep}
                        workflow={workflow}
                    />
                </>
            )}
            {hasGoalsAndObjectives && (
                <CustomForm
                    initialValues={goalsObjectivesProgress}
                    validationSchema={INTERVENTION_AND_PLAN_SCHEMA}
                    validateOnChange={true}
                    validateOnBlur={true}
                    render={renderForm}
                />
            )}
        </Card>
    );
};
