/**
 * gets the props necessary to setup an id used by end to end
 * testing platforms like cypress
 * @param {*} value the e2e id value
 * @param {*} e2eAttribute the e2e attribute to be used, if this is unset
 * otherwise data-e2e is used. this was added because react select filters out the
 * data-e2e arg
 *
 * @returns
 */
export const getAutomationDataAttr = (value, e2eAttribute = "data-e2e") => {
    if (process.env.REACT_APP_ENV !== "production") {
        return value ? { [e2eAttribute]: value } : {};
    }
    return {};
};
