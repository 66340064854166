export const UPDATED_CALENDAR_NPS_FLAG = "UPDATED_CALENDAR_NPS";
export const OPERATIONAL_RESTRICT_PATIENT_SCHEDULING = "OPERATIONAL_RESTRICT_PATIENT_SCHEDULING";
export const OPERATIONAL_MANUAL_PAYMENT_PROCESSING = "OPERATIONAL_MANUAL_PAYMENT_PROCESSING";
export const OPERATIONAL_CHECK_INSURANCE_ELIGIBILITY_FLAG =
    "OPERATIONAL_CHECK_INSURANCE_ELIGIBILITY_FLAG";
export const EXPERIMENTAL_GOALS_AND_OBJECTIVES = "EXPERIMENTAL_GOALS_AND_OBJECTIVES";
export const EXPERIMENTAL_PT_ARTICULATED_GOALS_STRENGTHS_MEDICAL_CAUTIONS =
    "EXPERIMENTAL_PT_ARTICULATED_GOALS_STRENGTHS_MEDICAL_CAUTIONS";
export const EXPERIMENTAL_MODALITY_FREQUENCY_DURATION = "EXPERIMENTAL_MODALITY_FREQUENCY_DURATION";
export const EXPERIMENTAL_GOALS_AND_OBJECTIVES_GUIDED_SESSION =
    "EXPERIMENTAL_GOALS_AND_OBJECTIVES_GUIDED_SESSION";
