import React from "react";
import { FormTextArea } from "../../../../../component-library/FormFields/FormTextArea/FormTextArea";
import { Text, TextTypes } from "../../../../../component-library";
import {
    INTERVENTION_AND_PLAN_CHARACTER_LIMIT,
    INTERVENTION_AND_PLAN_CHARACTER_LIMIT_ERROR,
} from "../../../../Common/GuidedVideoCall/Steps/ProviderWorkflow/InterventionAndPlan/InterventionAndPlanV2/InterventionAndPlanV2FormSchema";
import { CharacterCounter } from "../../../../../component-library/CharacterCounter/CharacterCounter";
import _ from "lodash";

export const InterventionAndPlanNoteField = ({
    editable,
    goalNumber,
    formikProps,
    isEditing,
    editHandler,
}) => {
    const hasCharacterLimitErrorAndEditable =
        editable &&
        formikProps.errors?.[goalNumber]?.interventions_and_plans ===
            INTERVENTION_AND_PLAN_CHARACTER_LIMIT_ERROR;

    if (!isEditing) {
        return (
            <>
                <Text
                    className={
                        hasCharacterLimitErrorAndEditable
                            ? "MemberProfileGoalsAndObjectiveNoteDisplayV2--form_field_view_only_error"
                            : "MemberProfileGoalsAndObjectiveNoteDisplayV2--form_field_view_only"
                    }
                    type={TextTypes.paragraph}>
                    {formikProps.values[goalNumber].interventions_and_plans}
                </Text>
                {hasCharacterLimitErrorAndEditable && (
                    <CharacterCounter
                        length={formikProps.values[goalNumber].interventions_and_plans.length}
                        characterLimit={INTERVENTION_AND_PLAN_CHARACTER_LIMIT}
                    />
                )}
            </>
        );
    }

    return (
        <>
            <FormTextArea
                inputClassName="MemberProfileGoalsAndObjectiveNoteDisplayV2--form_field"
                name={`${goalNumber}.interventions_and_plans`}
                formikProps={formikProps}
                triggerErrorOnChange
                onChangeCallBack={(name, value) => {
                    const dataToSave = _.cloneDeep(formikProps.values);
                    delete dataToSave["isEditingInternal"];
                    dataToSave[goalNumber] = {
                        ...dataToSave[goalNumber],
                        interventions_and_plans: value,
                    };

                    editHandler("goals_objectives_progress_v2", dataToSave);
                }}
            />
            {formikProps.errors?.[goalNumber]?.interventions_and_plans ===
                INTERVENTION_AND_PLAN_CHARACTER_LIMIT_ERROR && (
                <CharacterCounter
                    length={formikProps.values[goalNumber].interventions_and_plans.length}
                    characterLimit={INTERVENTION_AND_PLAN_CHARACTER_LIMIT}
                />
            )}
        </>
    );
};
